// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../libs/Env.res.js";
import * as Role from "./Role.res.js";
import * as User from "./User.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              railsEnv: field.required("railsEnv", Env.decoder),
              inMailer: field.required("inMailer", Json_Decode$JsonCombinators.bool),
              i18nLocale: field.required("i18nLocale", Json_Decode$JsonCombinators.string),
              i18nDefaultLocale: field.required("i18nDefaultLocale", Json_Decode$JsonCombinators.string),
              rorVersion: field.required("rorVersion", Json_Decode$JsonCombinators.string),
              rorPro: field.required("rorPro", Json_Decode$JsonCombinators.bool),
              href: field.required("href", Json_Decode$JsonCombinators.string),
              location: field.required("location", Json_Decode$JsonCombinators.string),
              scheme: field.required("scheme", Json_Decode$JsonCombinators.string),
              host: field.required("host", Json_Decode$JsonCombinators.string),
              port: field.required("port", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              pathname: field.required("pathname", Json_Decode$JsonCombinators.string),
              search: field.required("search", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              httpAcceptLanguage: field.required("httpAcceptLanguage", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              desktop: field.required("desktop", Json_Decode$JsonCombinators.bool),
              tablet: field.required("tablet", Json_Decode$JsonCombinators.bool),
              mobile: field.required("mobile", Json_Decode$JsonCombinators.bool),
              userLoginStatus: field.required("isUserSignedIn", User.LoginStatus.decoder),
              userIdentifier: field.required("userIdentifier", Json_Decode$JsonCombinators.string),
              userRole: field.required("userRole", Role.decoder),
              cookies: field.required("cookies", Json_Decode$JsonCombinators.id),
              serverSide: field.required("serverSide", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
